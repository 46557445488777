import React, { useState } from 'react';
import classNames from 'classnames';
import Helmet from "react-helmet";

import Layout from '../components/layout';
import Footer from '../components/footer';
import JobsBlock from '../components/jobsBlock';

const ContactPage = ({ location }) => {

    const [sending, setSending] = useState(false);
    const [status, setStatus] = useState('');

    const [form, setForm] = useState({
        name: {
            active: false,
            value: ''
        },
        email: {
            active: false,
            value: ''
        },
        phone: {
            active: false,
            value: ''
        },
        message: {
            active: false,
            value: ''
        }
    });

    const handleSubmit = (e) => {

        e.preventDefault();

        setSending(true);

        const items = ['name', 'email', 'phone', 'message'];

        let errors = {};
        let data = {};
        let error = false;

        items.forEach(field => {

            if (form[field].value === '') {

                errors[field] = {
                    ...form[field],
                    error: true
                };

                error = true;
            }

            data[field] = form[field].value;

        });

        setForm({
            ...form,
            ...errors
        });

        if (error) {

            setSending(false);

        } else {

            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data)
            };
    
            fetch(
                `https://api.atuo.digital/api/contact`,
                requestOptions
            )
            .then((response) => response.json())
            .then((resultData) => {
        
                if (resultData.success) {

                    setStatus('Enviado! Em breve retornaremos.');

                } else {

                    setStatus('Ops... Vamos tentar mais uma vez?');
                }
                
                setSending(false);

            })
            .catch((error) => {

                setStatus('Ops... Vamos tentar mais uma vez?');
                setSending(false);

            });
        }
        
    };

    const handleTextChange = (e) => {
        
        if (e.target.value !== '') {
            setForm({
                ...form,
                [e.target.name]: {
                    active: true,
                    value: e.target.value
                }
            });
        }
    };

    return (

        <Layout bg="black" location={location}>

            <Helmet>
                <title>ATUO - Entre em contato</title>
            </Helmet>

            <div className="container">
                <div className="py-60 ">
                    <h1 className="text-6xl md:text-8xl md:leading-tight">Aceita um bate-papo com um bom café?</h1>
                </div>

                <div className="w-full md:w-3/5 text-xl md:text-2xl">
                    <p className="pr-10">Queremos ouvir sobre você e construir um novo caminho para sua marca. Vamos juntos?</p>
                </div>

                <div className="flex flex-col md:flex-row justify-between items-center mt-20 pb-20 border-b">
                    <div className="w-full md:w-3/6 order-2 md:order-1">
                        {!status && (
                        <form onSubmit={handleSubmit}>
                            <div className="mb-6 float-label">
                                <input 
                                    id="name"
                                    name="name"
                                    type="text" 
                                    className={classNames({
                                        'form-input': true,
                                        'focus:ring-0': true,
                                        'focus:border-white': true,
                                        'error': form?.name?.error,
                                    })}
                                    onChange={handleTextChange}
                                    disabled={sending}
                                />

                                <label htmlFor="name" className={classNames({
                                    'active': form?.name?.active
                                })}>
                                    Seu nome*
                                </label>
                            </div>

                            <div className="mb-6 float-label">
                                <input 
                                    id="phone"
                                    name="phone"
                                    type="text" 
                                    className={classNames({
                                        'form-input': true,
                                        'focus:ring-0': true,
                                        'focus:border-white': true,
                                        'error': form?.phone?.error
                                    })}
                                    onChange={handleTextChange}
                                    disabled={sending}
                                />

                                <label htmlFor="phone" className={classNames({
                                    'active': form?.phone?.active
                                })}>
                                    Seu telefone*
                                </label>
                            </div>

                            <div className="mb-6 float-label">
                                <input 
                                    id="email"
                                    name="email"
                                    type="email" 
                                    className={classNames({
                                        'form-input': true,
                                        'focus:ring-0': true,
                                        'focus:border-white': true,
                                        'error': form?.email?.error
                                    })}
                                    onChange={handleTextChange}
                                    disabled={sending}
                                />

                                <label htmlFor="email" className={classNames({
                                    'active': form?.email?.active
                                })}>
                                    Seu e-mail*
                                </label>
                            </div>

                            <div className="mb-6 float-label">
                                <textarea 
                                    id="message"
                                    name="message"
                                    placeholder="Sua Mensagem*" 
                                    className={classNames({
                                        'form-input': true,
                                        'focus:ring-0': true,
                                        'focus:border-white': true,
                                        'error': form?.message?.error,
                                    })} 
                                    onChange={handleTextChange}
                                    disabled={sending}
                                />
                            </div>

                            {/* <p className="flex items-center mb-6">
                                <input 
                                    type="checkbox"
                                    className="form-checkbox" />
                                        
                                <label className="block">Eu concordo com os termos de privacidade.</label>
                            </p> */}

                            <p className="flex justify-end mt-10 w-full">
                                <input type="submit" value={ sending ? 'Enviando...' : 'Enviar' } className="form-button" disabled={sending} />
                            </p>

                        </form>
                        )}

                        {status && (
                            <p className="text-5xl md:text-7xl">{status}</p>
                        )}
                    </div>

                    <div className="w-full md:w-2/6 order-1 md:order-2 mb-0 md:mb-6 text-xl">
                        <p>Preencha os campos ao lado ou se preferir, nos ligue ou faça-nos uma visita!</p>
                    </div>
                </div>
                
            </div>

            <JobsBlock />
            <Footer bg="black" />
        </Layout>
    );
};

export default ContactPage;
