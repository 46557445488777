import React from 'react';

const JobsBlock = (props) => {
    return(
        <div className="container mt-20 hidden md:block">
            <div className="border-b pb-20">
                <h2 className="text-3xl mb-4">Quer fazer parte da nossa equipe?</h2>
                <a href="mailto:jobs@atuo.com.br" title="" className="text-xl font-light"><span className="text-yellow">jobs</span>@atuo.com.br</a>
            </div>
        </div>
    )
}

export default JobsBlock;